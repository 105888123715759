import { CustomerConfiguration, CustomerConfigurationType, ICustomerConfigurationCustomerValue } from './../models/customer-configuration.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomerConfigurationResponse } from '../models/customer-configuration.model';
import { environment } from 'src/environments/environment';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HelperService } from './helper.service';
import { UserService } from './user.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerConfigurationService {
  private customerConfigApiUrl = environment.customerServiceApiUrl;
  constructor(
    private httpClient: HttpClient,
    private helper: HelperService,
    private userService: UserService) { }

  private configCache$?: Observable<Record<CustomerConfigurationType, ICustomerConfigurationCustomerValue[]> | undefined>;
  get customerConfigurations$() {
    if (!this.configCache$) {
      this.configCache$ = this.getCustomerConfiguration().pipe(
        shareReplay(1),
        catchError(() => of(undefined))
      );
    }
    return this.configCache$;
  }

  private getCustomerConfiguration(): Observable<CustomerConfiguration> {
    return this.userService.userProfile$.pipe(
      switchMap(user => this.getConfigFromApi(user))
    );
  }

  private getConfigFromApi(user: User | null) {
    const configurationMap = new Map<CustomerConfigurationType, ICustomerConfigurationCustomerValue[]>();

    return this.httpClient.post<ICustomerConfigurationResponse[]>(
      this.customerConfigApiUrl + "/configuration/getbycustomercodes",
      user?.groupCustomerCode ? [user.groupCustomerCode] : [...(user?.xrefCompanyCodes ?? [])]
    )
      .pipe(
        map(config => {
          config.forEach(item => {
            let values: ICustomerConfigurationCustomerValue[] = [];
            if (configurationMap.has(item.configurationKey)) {
              values = configurationMap.get(item.configurationKey)!;
            }
            values.push({ customerCode: item.customerCode, value: this.helper.parseToPrimitive(item.value) });
            configurationMap.set(item.configurationKey, values);
          });
          return Object.fromEntries(configurationMap) as CustomerConfiguration;
        })
      );
  }
}